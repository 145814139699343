import React from "react";
import Navbar from "../Navbar";

const About = () => {
    return (
        <>
        <Navbar/>
        <h1>About</h1>
        </>
    )
}

export default About;